import request from '../../../../../../utils/request';
import BasePage from '../../../../../../components/table_page';
import { ProcessLog } from '../../../../../../modules';
import Form from '../form';

export default {
  name: 'activity_manage',
  extends: BasePage,
  data() {
    return {
      params: {
        actType: 'department_charge',
      },
      budgetState: null,
    };
  },
  components: {
    Form,
    ProcessLog,
  },
  created() {
    this.getConfigList('tpm_activity_department_list');
    request
      .get('/mdm/mdmParameterController/getParameterValue', {
        parameterCode: 'budget_use_date_rule',
      })
      .then((res) => {
        this.budgetState = res.result;
      });
  },
  methods: {
    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      // 审批通过 approved
      // 审批中 approving
      // 活动关闭 closed
      // 流程追回 interrupt
      // 驳回 rejected
      const { approveStatus, processCode } = row;
      if (code === 'process_log' && !processCode) {
        return false;
      }
      if (
        approveStatus
        && code === 'edit'
        && (approveStatus === 'interrupt'
          || approveStatus === 'closed'
          || approveStatus === 'approving'
          || approveStatus === 'approved')
      ) {
        return false;
      }
      if (
        approveStatus
        && code === 'submit_approval'
        && (approveStatus === 'closed'
          || approveStatus === 'approving'
          || approveStatus === 'approved')
      ) {
        return false;
      }

      return true;
    },
    modalClick({ val, row }) {
      const { code } = val;
      if (code === 'add' || code === 'edit' || code === 'view') {
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
          budgetState: this.budgetState,
        };
        this.modalConfig.title = val.name || '查看';
        this.openFull();
      } else if (code === 'submit_approval') {
        request
          .get('/tpm/tpmActController/query', {
            id: row.actId || row.id,
          })
          .then((res) => {
            if (res.success) {
              const rowData = { ...res.result };
              rowData.budgetControlVos = rowData.editBudgetVos;
              rowData.saveType = '5';
              this.onSubmit({ row: rowData, submitUrl: '/tpm/tpmActController/approve' });
            }
          });
      } else if (code === 'process_log') {
        this.formConfig = {
          ...this.formConfig,
          code,
          row: { processNo: row.processCode },
        };

        this.modalConfig.title = '流程日志';

        this.formName = 'ProcessLog';
        this.openDrawer();
      }
    },
    // 保存回调
    onSubmit(params) {
      const configParams = {
        ...params,
        code: 'approve',
      };

      this.$emit('approve', configParams);
    },
  },
};
